.c-private-header .c-organisation-navigation {
  top: 3.75rem;
  right: 5.5rem;
}

.c-private-header .c-app-header__end .c-organisation-menu {
  margin-top: 0.25rem;
}

.c-private-header .c-user-navigation {
  top: 3.75rem;
  right: 2rem;
}

.c-app-header--partner-logo .c-app-header__start {
  top: 0;
  padding: 0;
}

.c-app-header--partner-logo .c-app-header__image {
  height: 4.375rem;
  margin: 0;
}

.c-app-header--partner-logo .c-app-header__icon-link {
  line-height: 0;
}

.c-plan-calculator {
  border-radius: var(--g-border-radius-medium);
  position: relative;
  max-width: unset;
}

.c-plan-calculator__select-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--g-spacing);
}

.c-plan-calculator__select-input-container {
  width: var(--g-plan-calculator-input-container);
}

.c-plan-calculator__header {
  color: var(--g-color-white);
  left: 50%;
}

.c-plan-calculator__body {
  background-color: var(--g-color-grey-100);
  border-radius: 0 0 var(--g-border-radius-medium) var(--g-border-radius-medium);
}

.c-plan-calculator__footer {
  padding: var(--g-spacing-x-small) 0 var(--g-spacing-2x-large) 0;
  text-align: center;
}

.c-plan-calculator__title {
  background-color: var(--g-color-red-300);
  border-radius: 50px;
  color: var(--g-color-white);
  left: 50%;
  padding: var(--g-spacing-x-small) var(--g-spacing);
  position: absolute;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.c-plan-calculator__price {
  align-items: center;
  background-color: var(--g-color-brand);
  border-radius: 10px 10px 0 0;
  color: var(--g-color-white);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 var(--g-spacing);
  padding: var(--g-spacing-3x-large);
}

.c-plan-calculator__price p {
  margin-right: 0;
}

.c-plan-calculator__row {
  border-bottom: 1px solid var(--g-color-grey);
  padding: var(--g-spacing);
}

.c-plan-calculator__row,
.c-plan-calculator__select {
  display: block;
}

.c-plan-calculator__row--last {
  border-color: transparent;
}

.c-plan-calculator__row .c-input-field {
  align-items: center;
}

.c-plan-calculator__row .c-input-field label {
  margin-right: var(--g-spacing);
}

.c-plan-calculator__row .c-input-field label div {
  margin: 0;
}

.c-plan-calculator__row label[for='payg'] {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: var(--g-spacing-small);
}

.c-plan-calculator__row .c-input-field {
  display: block !important;
  margin-bottom: 0;
}


.c-plan-calculator__row label[for='payg'] .h-spacing-small {
  margin-bottom: 0;
}

.c-plan-calculator .c-select-list__input {
  background-color: var(--white);
}

.c-plan-calculator .c-toggle-switch__wrapper {
  width: 100%;
}

@media (max-width: 50em) {
  .c-plan-calculator {
    max-width: unset;
  }

}

:root {
  /* Fonts */
  --font-header: rift;
  --font-body: calibri;

  /* COLOURS */
  --black: #000;
  --burgandy-100: #ebe7ed;
  --burgundy-400: #972868;
  --grey-900: #2a2e2f;
  --grey-800: #42536e;
  --grey-400: #a8b2b4;
  --grey-300: #cbccce;
  --grey-200: #ecedf0;
  --grey-100: #f6f6f7;
  --green-400: #4bc2ca;
  --green-500: #39acb4;
  --green-600: #239098;
  --green-700: #15666c;
  --purple-700: #673ab7;
  --color-primary: #972868;
  --monza: #c90035;
  --pear: #cddc39;
  --pizazz: #ff8a00;
  --red-400: #f44336;
  --red-300: #ff4d4d;
  --yellow-400: #ffc96c;
  --white: white;
  --pink: #fa6980;

  /* FONTS */
  --g-font-size-x-small: 0.75rem;
  --g-font-size-small: 0.875rem;
  --g-font-size: 1rem;
  --g-font-size-large: 1.125rem;
  --g-font-size-x-large: 1.5rem;

  --g-font-weight-200: 200;
  --g-font-weight-400: 400;
  --g-font-weight-500: 500;
  --g-font-weight-600: 600;
  --g-font-weight-700: 700;

  /* SPACING */
  --spacing-base: 0.25rem;
  --spacing-0-5: calc(var(--spacing-base) / 2);
  --spacing-1: calc(var(--spacing-base) * 1);
  --spacing-2: calc(var(--spacing-base) * 2);
  --spacing-3: calc(var(--spacing-base) * 3);
  --spacing-4: calc(var(--spacing-base) * 4); /* 1rem */
  --spacing-5: calc(var(--spacing-base) * 5);
  --spacing-6: calc(var(--spacing-base) * 6);
  --spacing-7: calc(var(--spacing-base) * 7);
  --spacing-8: calc(var(--spacing-base) * 8); /* 2rem */
  --spacing-9: calc(var(--spacing-base) * 9);
  --spacing-10: calc(var(--spacing-base) * 10);
  --spacing-11: calc(var(--spacing-base) * 11);
  --spacing-12: calc(var(--spacing-base) * 12); /* 3rem */
  --spacing-13: calc(var(--spacing-base) * 13);
  --spacing-14: calc(var(--spacing-base) * 14);
  --spacing-15: calc(var(--spacing-base) * 15);
  --spacing-16: calc(var(--spacing-base) * 16); /* 4rem */
  --spacing-17: calc(var(--spacing-base) * 17);
  --spacing-18: calc(var(--spacing-base) * 18);
  --spacing-19: calc(var(--spacing-base) * 19);
  --spacing-20: calc(var(--spacing-base) * 20); /* 5rem */
  --spacing-24: calc(var(--spacing-base) * 24); /* 6rem */
  --spacing-28: calc(var(--spacing-base) * 28); /* 7rem */
  --spacing-32: calc(var(--spacing-base) * 32); /* 8rem */
  --spacing-36: calc(var(--spacing-base) * 36); /* 9rem */
  --spacing-40: calc(var(--spacing-base) * 40); /* 10rem */
  --spacing-48: calc(var(--spacing-base) * 48); /* 11rem */
  --spacing-52: calc(var(--spacing-base) * 52); /* 13rem */
  --spacing-60: calc(var(--spacing-base) * 60); /* 15rem */
  --spacing-72: calc(var(--spacing-base) * 72); /* 18rem */
  --spacing-80: calc(var(--spacing-base) * 80); /* 20rem */
  --spacing-88: calc(var(--spacing-base) * 88); /* 22rem */

  /* INPUT */
  --g-default-input-height: 3.5rem;
  --g-plan-calculator-input-container: 7rem;

  /* SIDEBAR */
  --g-side-bar-padding-bottom: 7rem;

  /* QR Code */
  --g-hidden-qr-code-generator-screen-offset: -999999rem;

  /* Cookie Consent  */
  --z-cookie-consent: 9999;

  /* Z  */
  --z-on-top: 1;

  /* Font family */
  --g-font-primary: 'calibri';
  --g-font-secondary: 'rift';
}

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

ul {
  margin-top: 0;
}

button {
  background-color: transparent;
  line-height: 1.3;
}

img {
  max-width: 100%;
}

fieldset {
  border: none;
}

table {
  border-collapse: collapse;
}

th {
  margin: 0;
  padding: 0;
}

td {
  background-color: var(--white);
}

input,
textarea {
  font-family: inherit;
}

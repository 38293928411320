.c-page-layout-template .c-container {
  margin-left: auto;
  margin-right: auto;
  padding: var(--g-spacing-x-large) 0;
  max-width: 95.25rem;
  width: 86%;
}

.c-page-layout-template .c-container--xs {
  max-width: 48rem;
}

.c-page-layout-template .c-container--small {
  max-width: 62.5rem;
}

.c-page-layout-template .c-container--large {
  max-width: 100rem;
}

.c-page-layout-template {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-page-layout-template .c-dropdown-toggle {
  cursor: pointer;
}

.c-page-layout-template .c-dropdown-navigation__list {
  margin: 0;
}

.c-page-layout-template__main {
  position: relative;
  flex: 1;
}


.c-page-layout-template .c-app-header__end .c-organisation-navigation,
.c-page-layout-template .c-app-header__end .c-user-navigation {
  top: var(--g-spacing-4x-large);
}

.c-page-layout-template .c-app-header__end .c-user-navigation {
  right: var(--g-spacing-x-small);
}

.c-page-layout-template .c-app-header__end .c-organisation-navigation {
  right: 3.65rem;
  z-index: 3;
}

.c-app-header__middle {
  display: flex;
  align-items: center;
}

.c-app-header--partner-logo__image {
  height: var(--g-spacing-3x-large);
}

.c-app-header__skeleton-container {
  padding: 0 var(--g-spacing-x-small);
}

.c-app-header--partner-logo .c-app-header__start {
  top: 0;
  padding: 0;
}

.c-app-header--partner-logo .c-app-header__image {
  height: 4.375rem;
  margin: 0;
}

.c-app-header--partner-logo .c-app-header__icon-link {
  line-height: 0;
}

.c-page-layout-template--narrow .c-container {
  max-width: 42rem;
}

.h-hide-visually {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap; /* 1 */
  width: 1px;
}

.h-hide {
  display: none;
}

.h-input-label {
  background-color: var(--white);
}

.bg-white {
  background-color: var(--white);
}

.bg-black {
  background-color: var(--black);
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-primary-opacity {
  background-color: rgba(75, 194, 202, 0.1);
}

.bg-grey-100 {
  background-color: var(--grey-100);
}

.bg-grey-200 {
  background-color: var(--grey-200);
}

.bg-grey-300 {
  background-color: var(--grey-300);
}

.bg-grey-400 {
  background-color: var(--grey-400);
}

.bg-grey-900 {
  background-color: var(--grey-900);
}

.bg-green-400 {
  background-color: var(--green-400);
}

.bg-green-500 {
  background-color: var(--green-500);
}

.bg-green-600 {
  background-color: var(--green-600);
}

.bg-green-700 {
  background-color: var(--green-700);
}

.bg-red-300 {
  background-color: var(--red-300);
}

.bg-red-400 {
  background-color: var(--red-400);
}

.bg-burgandy-100 {
  background-color: var(--burgandy-100);
}

.bg-burgundy-400 {
  background-color: var(--burgundy-400);
}

.bg-yellow-400 {
  background-color: var(--yellow-400);
}

.bg-active {
  background-color: var(--g-color-green-300);
}

.bg-pink-400 {
  background-color: var(--g-color-pink-400);
}

.bg-transparent {
  background-color: transparent;
}

.color-white {
  color: var(--white);
}

.color-black {
  color: var(--black);
}

.color-grey-200 {
  color: var(--grey-200);
}

.color-grey-300 {
  color: var(--grey-300);
}

.color-grey-400 {
  color: var(--grey-400);
}

.color-grey-800 {
  color: var(--grey-800);
}

.color-red-300 {
  color: var(--red-300);
}

.color-red-400 {
  color: var(--red-400);
}

.color-primary {
  color: var(--color-primary);
}

.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.gap-1 {
  gap: var(--spacing-1);
}

.gap-2 {
  gap: var(--spacing-2);
}

.gap-3 {
  gap: var(--spacing-3);
}

.gap-4 {
  gap: var(--spacing-4);
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.items-center {
  align-items: center;
}

.items-even {
  align-items: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-even {
  justify-content: space-evenly;
}

/* Fonts */

.font-header {
  font-family: var(--font-header);
}

.font-body {
  font-family: var(--font-body);
}

.weight-200 {
  font-weight: 200;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.text-no-wrap {
  text-wrap: nowrap;
}

.text-pretty-wrap {
  text-wrap: pretty;
}

.weight-700 {
  font-weight: 700;
}

/* Display */
.none {
  display: none;
}

.block {
  display: block;
}

/* Position */
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

/* Padding */

.p-0-0-5 {
  padding: 0.05rem;
}

.p-0\.5 {
  padding: 0.125rem;
}

.p-1 {
  padding: var(--spacing-1);
}

.p-2 {
  padding: var(--spacing-2);
}

.p-3 {
  padding: var(--spacing-3);
}

.p-4 {
  padding: var(--spacing-4);
}

.p-6 {
  padding: var(--spacing-6);
}

.p-8 {
  padding: var(--spacing-8);
}

.p-12 {
  padding: var(--spacing-12);
}

.pl-2 {
  padding-left: var(--spacing-2);
}

.pl-4 {
  padding-left: var(--spacing-4);
}

.pl-6 {
  padding-left: var(--spacing-6);
}

.pl-8 {
  padding-left: var(--spacing-8);
}

.pl-12 {
  padding-left: var(--spacing-12);
}

.pl-0 {
  padding-left: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
}

.px-2 {
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
}

.px-3 {
  padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
}

.px-4 {
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
}

.px-6 {
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
}

.px-7 {
  padding-left: var(--spacing-7);
  padding-right: var(--spacing-7);
}

.px-8 {
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
}

.px-12 {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-2 {
  padding-bottom: var(--spacing-2);
  padding-top: var(--spacing-2);
}

.py-3 {
  padding-bottom: var(--spacing-3);
  padding-top: var(--spacing-3);
}

.py-4 {
  padding-bottom: var(--spacing-4);
  padding-top: var(--spacing-4);
}

.py-6 {
  padding-bottom: var(--spacing-6);
  padding-top: var(--spacing-6);
}

.py-8 {
  padding-bottom: var(--spacing-8);
  padding-top: var(--spacing-8);
}

.py-12 {
  padding-bottom: var(--spacing-12);
  padding-top: var(--spacing-12);
}

.py-16 {
  padding-bottom: var(--spacing-16);
  padding-top: var(--spacing-16);
}

.py-20 {
  padding-bottom: var(--spacing-20);
  padding-top: var(--spacing-20);
}

.py-24 {
  padding-bottom: var(--spacing-24);
  padding-top: var(--spacing-24);
}

.py-32 {
  padding-bottom: var(--spacing-32);
  padding-top: var(--spacing-32);
}

.py-40 {
  padding-bottom: var(--spacing-40);
  padding-top: var(--spacing-40);
}

.py-48 {
  padding-bottom: var(--spacing-48);
  padding-top: var(--spacing-48);
}

.pb-0 {
  padding-bottom: 0;
}

.pb-2 {
  padding-bottom: var(--spacing-2);
}

.pb-8 {
  padding-bottom: var(--spacing-8);
}

.pb-16 {
  padding-bottom: var(--spacing-16);
}

.pb-18 {
  padding-bottom: var(--spacing-18);
}

.pb-20 {
  padding-bottom: var(--spacing-20);
}

.pb-24 {
  padding-bottom: var(--spacing-24);
}

.pb-32 {
  padding-bottom: var(--spacing-32);
}

.pt-0 {
  padding-top: 0;
}

.pt-2 {
  padding-top: var(--spacing-2);
}

.pt-6 {
  padding-top: var(--spacing-6);
}

.pt-8 {
  padding-top: var(--spacing-8);
}

.pt-16 {
  padding-top: var(--spacing-16);
}

.pt-20 {
  padding-top: var(--spacing-20);
}

.pt-24 {
  padding-top: var(--spacing-24);
}

.pt-40 {
  padding-top: var(--spacing-40);
}

.pt-48 {
  padding-top: var(--spacing-48);
}

.p-0 {
  padding: 0;
}

/* Margin */
.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: var(--spacing-2);
}

.mb-3 {
  margin-bottom: var(--spacing-3);
}

.mb-4 {
  margin-bottom: var(--spacing-4);
}

.mb-6 {
  margin-bottom: var(--spacing-6);
}

.mb-8 {
  margin-bottom: var(--spacing-8);
}

.mb-12 {
  margin-bottom: var(--spacing-12);
}

.mb-16 {
  margin-bottom: var(--spacing-16);
}

.mb-24 {
  margin-bottom: var(--spacing-24);
}

.mb-28 {
  margin-bottom: var(--spacing-28);
}

.ml-0 {
  margin-left: 0;
}

.ml-2 {
  margin-left: var(--spacing-2);
}

.ml-4 {
  margin-left: var(--spacing-4);
}

.ml-6 {
  margin-left: var(--spacing-6);
}

.ml-8 {
  margin-left: var(--spacing-8);
}

.ml-12 {
  margin-left: var(--spacing-12);
}

.ml-16 {
  margin-left: var(--spacing-16);
}

.ml-20 {
  margin-left: var(--spacing-20);
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.-ml-1 {
  margin-left: calc(-1 * var(--spacing-1));
}

.-ml-2 {
  margin-left: calc(-1 * var(--spacing-2));
}

.mr-2 {
  margin-right: var(--spacing-2);
}

.mr-3 {
  margin-right: var(--spacing-3);
}

.mr-4 {
  margin-right: var(--spacing-4);
}

.mr-6 {
  margin-right: var(--spacing-6);
}

.mr-8 {
  margin-right: var(--spacing-8);
}

.mr-12 {
  margin-right: var(--spacing-12);
}

.mr-18 {
  margin-right: var(--spacing-18);
}

.mr-24 {
  margin-right: var(--spacing-24);
}

.mt-auto {
  margin-top: auto;
}

.mt-2 {
  margin-top: var(--spacing-2);
}

.mt-4 {
  margin-top: var(--spacing-4);
}

.mt-6 {
  margin-top: var(--spacing-6);
}

.mt-8 {
  margin-top: var(--spacing-8);
}

.mt-12 {
  margin-top: var(--spacing-12);
}

.mt-16 {
  margin-top: var(--spacing-16);
}

.mt-20 {
  margin-top: var(--spacing-20);
}

.mt-24 {
  margin-top: var(--spacing-24);
}

.mt-32 {
  margin-top: var(--spacing-32);
}

.mt-36 {
  margin-top: var(--spacing-36);
}

.mt-40 {
  margin-top: var(--spacing-40);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-2 {
  margin-left: calc(-1 * var(--spacing-2));
  margin-right: calc(-1 * var(--spacing-2));
}

.-mx-4 {
  margin-left: calc(-1 * var(--spacing-4));
  margin-right: calc(-1 * var(--spacing-4));
}

.-mx-8 {
  margin-left: calc(-1 * var(--spacing-8));
  margin-right: calc(-1 * var(--spacing-8));
}

.-mx-12 {
  margin-left: calc(-1 * var(--spacing-12));
  margin-right: calc(-1 * var(--spacing-12));
}

.my-1 {
  margin-bottom: var(--spacing-1);
  margin-top: var(--spacing-1);
}

.my-2 {
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.my-auto {
  margin-bottom: auto;
  margin-top: auto;
}

.m-0 {
  margin: 0;
}

/* Width */
.w-full {
  width: 100%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-1\/2 {
  width: 50%;
}

.w-2\/3 {
  width: 66.6666%;
}

.w-3 {
  width: var(--spacing-3);
}

.w-4 {
  width: var(--spacing-4);
}

.w-5 {
  width: var(--spacing-5);
}

.w-6 {
  width: var(--spacing-6);
}

.w-8 {
  width: var(--spacing-8);
}

.w-10 {
  width: var(--spacing-10);
}

.w-11 {
  width: var(--spacing-11);
}

.w-12 {
  width: var(--spacing-12);
}

.w-20 {
  width: var(--spacing-20);
}

.w-28 {
  width: var(--spacing-28);
}

.w-40 {
  width: var(--spacing-40);
}

.w-48 {
  width: var(--spacing-48);
}

/* Height */
.h-1 {
  height: var(--spacing-1);
}

.h-2 {
  height: var(--spacing-2);
}

.h-3 {
  height: var(--spacing-3);
}

.h-4 {
  height: var(--spacing-4);
}

.h-5 {
  height: var(--spacing-5);
}

.h-6 {
  height: var(--spacing-6);
}

.h-8 {
  height: var(--spacing-8);
}

.h-11 {
  height: var(--spacing-11);
}

.h-12 {
  height: var(--spacing-12);
}

.h-13 {
  height: var(--spacing-13);
}

.h-14 {
  height: var(--spacing-14);
}

.h-28 {
  height: var(--spacing-28);
}

.h-full {
  height: 100%;
}

/* Max width */

.max-w-3\/5 {
  max-width: 60%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-16 {
  max-width: var(--spacing-16);
}

.max-w-18 {
  max-width: var(--spacing-18);
}

.max-w-20 {
  max-width: var(--spacing-20);
}

.max-w-12 {
  max-width: var(--spacing-16);
}

.max-w-24 {
  max-width: var(--spacing-24);
}

.max-w-28 {
  max-width: var(--spacing-28);
}

.max-w-60 {
  max-width: var(--spacing-60);
}

.max-w-48 {
  max-width: var(--spacing-48);
}

.max-w-72 {
  max-width: var(--spacing-72);
}

.max-w-88 {
  max-width: var(--spacing-88);
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.min-w-auto {
  min-width: auto;
}

.max-h-8 {
  max-height: var(--spacing-8);
}

/* Min height */
.min-h-screen {
  min-height: 100vh;
}

/* Positioning */
.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-6\/5 {
  top: 120%;
}

.top-1\/2 {
  top: 50%;
}

.top-full {
  top: 100%;
}

/* Border */
.border {
  border: 1px solid;
}

.border-2 {
  border: 2px solid;
}

.border-b {
  border-bottom: 1px solid;
}

.border-l {
  border-left: 1px solid;
}

.border-r {
  border-right: 1px solid;
}

.border-t {
  border-top: 1px solid;
}

.border-none {
  border: none;
}

.border-grey-100 {
  border-color: var(--grey-100);
}

.border-grey-200 {
  border-color: var(--grey-200);
}

.border-grey-300 {
  border-color: var(--grey-300);
}

.border-burgundy-400 {
  border-color: var(--burgundy-400);
}

.border-primary {
  border-color: var(--color-primary);
}

.border-transparent {
  border-color: transparent;
}

.border-l-5 {
  border-left: 5px solid;
}

.border-white {
  border-color: var(--white);
}

/* Border radius */

.rounded-sm {
  border-radius: 5px;
}

.rounded {
  border-radius: 10px;
}

.rounded-b {
  border-radius: 0 0 10px 10px;
}

.rounded-t {
  border-radius: 10px 10px 0 0;
}

.rounded-full {
  border-radius: 50px;
}

.rounded-circle {
  border-radius: 50%;
}

/* Lists */
.list-none {
  list-style: none;
}

/* Z-index */
.z-2 {
  z-index: 2;
}

.z-50 {
  z-index: 50;
}

/* Text */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.5rem;
  line-height: 1.85rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.line-height-none {
  line-height: 0;
}

.text-underline {
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

/* Transform */
.-translate-x-1\/2 {
  transform: translateX(-50%);
}

.-translate-y-1\/2 {
  transform: translateY(-50%);
}

.-translate-1\/2 {
  transform: translate(-50%, -50%);
}

/* Transition */
.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Accessibility  */
.sr-only {
  border-width: 0;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

/* Pointer */
.pointer {
  cursor: pointer;
}

/* Animations */
.fade-in {
  animation: fadeUp 0.8s ease-out 0.2s forwards;
  opacity: 0;
}

/* Transforms */
.scale-2 {
  transform: scale(1.2);
}

/* Opacity */
.opacity-0 {
  opacity: 0;
}

.opacity-75 {
  opacity: 0.75;
}

.ring-primary {
  box-shadow: 0 0 0 2px var(--green-400);
  border: 2px solid var(--white);
}

.hover\:bg-purple-700:hover {
  background-color: var(--purple-700);
}

.hover\:bg-red-300:hover {
  background-color: var(--red-300);
}

.hover\:bg-grey-200:hover {
  background-color: var(--grey-200);
}

.hover\:bg-grey-300:hover {
  background-color: var(--grey-300);
}

.hover\:bg-burgundy-400:hover {
  background-color: var(--burgundy-400);
}

.hover\:bg-green-500:hover {
  background-color: var(--green-500);
}

.hover\:bg-yellow-400:hover {
  background-color: var(--yellow-400);
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:color-black:hover {
  color: var(--black);
}

.hover\:color-white:hover {
  color: var(--white);
}

.hover\:color-primary:hover {
  color: var(--color-primary);
}

.hover\:opacity-1:hover {
  opacity: 1;
}

.hover\:border-burgundy-400:hover {
  border-color: var(--burgundy-400);
}

.hover\:border-2:hover {
  border: 2px solid;
}

.hover\:border-grey-200:hover {
  border-color: var(--grey-200);
}

.hover\:border-primary:hover {
  border-color: var(--color-primary);
}

.focus\:ring-primary:focus {
  box-shadow: 0 0 0 2px var(--green-400);
  border: 2px solid var(--white);
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.outline-none {
  outline: none;
}

.word-break-all {
  word-break: break-all;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Small */
@media (min-width: 600px) {
  .sm\:min-h-screen {
    min-height: 90vh;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:ml-4 {
    margin-left: var(--spacing-4);
  }
}

/* Medium */
@media (min-width: 769px) {
  .md\:inline-block {
    display: inline-block;
  }

  .md\:none {
    display: none;
  }

  .md\:flex {
    display: flex;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-start {
    align-items: flex-start;
  }

  .md\:gap-1 {
    gap: var(--g-spacing);
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:row-reverse {
    flex-direction: row-reverse;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:w-88 {
    width: var(--spacing-88);
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-2\/3 {
    width: 66.666666%;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:max-w-18 {
    max-width: var(--spacing-18);
  }

  .md\:max-w-20 {
    max-width: var(--spacing-20);
  }

  .md\:max-w-60 {
    max-width: var(--spacing-60);
  }

  .md\:max-w-24 {
    max-width: var(--spacing-24);
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-4 {
    margin-bottom: var(--spacing-4);
  }

  .md\:mb-12 {
    margin-bottom: var(--spacing-12);
  }

  .md\:min-h-screen {
    min-height: 90vh;
  }

  .md\:mt-12 {
    margin-top: var(--spacing-12);
  }

  .md\:my-auto {
    margin-bottom: auto;
    margin-top: auto;
  }

  .md\:mr-24 {
    margin-right: var(--spacing-24);
  }

  .md\:ml-8 {
    margin-left: var(--spacing-8);
  }

  .md\:ml-12 {
    margin-left: var(--spacing-12);
  }

  .md\:p-12 {
    padding: var(--spacing-12);
  }

  .md\:py-12 {
    padding-bottom: var(--spacing-12);
    padding-top: var(--spacing-12);
  }

  .md\:py-32 {
    padding-bottom: var(--spacing-32);
    padding-top: var(--spacing-32);
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }
}

/* Large */
@media (min-width: 1024px) {
  .lg\:none {
    display: none;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:mb-24 {
    margin-bottom: var(--spacing-24);
  }

  .lg\:min-h-screen {
    min-height: 100vh;
  }

  .lg\:mr-24 {
    margin-right: var(--spacing-24);
  }

  .lg\:mt-12 {
    margin-top: var(--spacing-12);
  }

  .lg\:py-28 {
    padding-bottom: var(--spacing-28);
    padding-top: var(--spacing-28);
  }

  .lg\:py-32 {
    padding-bottom: var(--spacing-32);
    padding-top: var(--spacing-32);
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:max-w-4\/5 {
    width: 80%;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:mt-12 {
    margin-top: var(--spacing-12);
  }

  .lg\:items-center {
    align-items: center;
  }
}



.c-payment-options-container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: var(--g-spacing-small);
  margin-bottom: var(--g-spacing);
}

.c-payment-options {
  display: flex;
  flex-wrap: wrap;
  gap: var(--g-spacing-x-small);
  justify-content: flex-end;
  flex-grow: 1;
}

.c-payment-options__title {
  font-size: var(--g-font-size-x-large);
  line-height: 1;
  margin-bottom: 0;
}

.c-payment-option {
  align-items: center;
  border-radius: 10px;
  display: flex;
  border: 1px solid var(--grey-300);
  padding: var(--spacing-4) var(--spacing-2);
  gap: var(--g-spacing-2x-small);
}

@media (max-width: 50em) {
  .c-payment-options-container {
    flex-direction: column;
  }

  .c-payment-options {
    justify-content: center;
  }
}

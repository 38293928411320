.bg-gradient {
  background: linear-gradient(45deg, #173567, #972868);
}

.bg-gradient-wave {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-gradient-wave--whole {
  background-image: url(/images/background-whole.svg);
}

.bg-gradient-wave--minimal {
  background-image: url(/images/wave-panel-bg.svg);
}

.c-contact-form__form .c-form__body {
  margin-bottom: var(--g-spacing);
}

.c-contact-form__form .c-form__body .c-input__container {
  margin-bottom: var(--g-spacing);
}

.c-error-message {
  color: var(--g-color-red-400);
  font-size: var(--g-font-size-small);
  padding: 0 0 var(--g-spacing-small) 0;
}

.c-contact-form__form .c-form__footer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.c-breadcrumb-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.c-breadcrumb-wrapper .c-menu {
  flex: 1;
}

.c-breadcrumb-wrapper .c-information-box {
  display: none;
}

.c-breadcrumb-with-avatar-and-cta .c-breadcrumb-with-avatar {
  margin-top: 0;
}

.c-paypal-form__select-wrapper .c-input-field {
  flex-direction: column;
}

.c-container {
  margin-top: var(--g-spacing-x-large);
  margin-left: auto;
  margin-right: auto;
  max-width: 95.25rem;
  width: 86%;
}

.c-container--xs {
  max-width: 48rem;
}

.c-container--small {
  max-width: 62.5rem;
}

.c-container--large {
  max-width: 100rem;
}

.c-container--transition {
  transition: var(--g-transition-time) ease-in-out;
}

.c-copy-button:hover {
  cursor: pointer;
}

.c-input--is-error {
  border-color: var(--g-color-red-600);
}

.c-color-picker-preview__button {
  border: 1px solid var(--g-color-grey-200);
}

.c-list-items {
  padding-left: 0;
}

.c-settings-button {
  border: 0;
}

.c-breadcrumb-item {
  margin-left: 0;
}

.c-settings-button:hover {
  cursor: pointer;
}

.c-text-snippet {
  padding: 0;
}

.c-ui-graphic {
  max-width: 100%;
  position: relative;
}

.c-page-preview-card {
  text-align: left;
}

.c-page-preview-card--is-hero > .c-page-preview-card__title {
  font-size: 5rem;
  line-height: 5rem;
}

.c-action__back-to-action {
  cursor: pointer;
}

.c-sidebar-section--aligned {
  display: flex;
  flex-direction: column;
  position: relative;
}

.c-sidebar-section__tooltip {
  position: absolute;
  right: 0;
  top: 0;
}

.c-tooltip__bubble {
  z-index: 1;
}

.c-sidebar-save-button {
  background-color: var(--g-color-grey-50);
  bottom: 0;
  display: flex;
  gap: var(--g-spacing);
  justify-content: center;
  padding: var(--g-spacing);
  position: fixed;
  right: 0;
  width: var(--c-sidebar-max-width);
}

@media (max-width: 64em) {
  .c-sidebar-save-button {
    width: 100% !important;
  }
}

.c-left-sidebar__list {
  display: flex;
  flex-direction: column;
  gap: var(--g-spacing-x-small);
}

.c-left-sidebar__list-item,
.c-left-sidebar__partner-code {
  align-items: center;
  border-radius: var(--g-border-radius-small);
  display: flex;
  font-size: var(--g-font-size-small);
  gap: 0.563rem;
  margin: 0;
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
}

.c-left-sidebar__list-item--active {
  background-color: var(--g-color-grey-50);
}

.c-left-sidebar__list-item__icon {
  height: 0.813rem;
  width: 0.813rem;
}


@media (min-width: 64em) {
  .c-ui-graphic {
    position: absolute;
    right: 0;
    top: 50%;
    max-width: 42rem;
    transform: translate(40%, -40%);
  }
}

@media (min-width: 81.25em) {
  .c-ui-graphic {
    transform: translate(0, -40%);
  }
}

.c-menu {
  margin: 0;
}

.c-qr-code-type-radio-buttons > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  gap: var(--g-spacing-2x-small);
}

.c-qr-code-type-radio-buttons > div:nth-child(1) {
  display: none;
}


.c-qr-code-type-radio-buttons > div:nth-child(2) > div {
  flex: 1 25% 25%;
}

.c-qr-code-download-type-radio-buttons {
  margin: 0 auto;
  flex-direction: unset;
  justify-content: center;
  gap: var(--g-spacing);
}

.c-radio-button__item:disabled ~ label {
  cursor: not-allowed;
}

.c-radio-button__item:disabled ~ label::before {
  background-color: var(--grey-200);
  cursor: not-allowed;
}

.c-radio-button__item:disabled {
  cursor: not-allowed;
}

.c-radio-button__item:checked ~ label::before,
.c-radio-button__item:focus ~ label::before {
  background-color: var(--purple-700);
}

.c-qr-code-download-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: var(--g-spacing-large);
}

.c-phone-input-box {
  appearance: none !important;
  background-color: white !important;
  border-bottom: 1px solid var(--g-color-grey-200, #e4e4e4) !important;
  border-radius: var(--g-spacing-2x-small) !important;
  border: 1px solid #fff !important;
  font-family: var(--g-font-primary) !important;
  font-size: var(--g-font-size) !important;
  font-weight: var(--g-font-weight-light) !important;
  padding: var(--g-spacing-small) !important;
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s !important;
  width: 90% !important;
}

.country-name,
.dial-code {
  font-family: var(--font-body);
  font-size: var(--g-font-size-large);
  margin-left: var(--g-spacing-x-small);
}

.selected-flag {
  padding: 0 0 0 var(--g-spacing);
}

.form-control {
  margin-left: var(--g-spacing) !important;
}

.flag {
  transform: scale(1.5);
}

.react-tel-input .country-list .search {
  padding: 0;
  z-index: var(--z-on-top);
}

.flag-dropdown {
  background-color: var(--g-color-white) !important;
  border: none !important;
  width: 4rem !important;
}

.c-sidebar-save-button {
  bottom: 0;
  background-color: var(--g-color-grey-50);
  display: flex;
  justify-content: center;
  padding: var(--g-spacing);
  position: fixed;
  right: 0;
  width: var(--c-sidebar-max-width);
}


.react-tel-input {
  height: var(--g-default-input-height);
}

.form-control {
  background-color: transparent !important;
  border-bottom: 1px solid var(--g-color-grey-200, #e4e4e4) !important;
  border-radius: var(--g-spacing-2x-small) !important;
  border: 1px solid #fff !important;
  font-family: var(--g-font-primary) !important;
  font-size: var(--g-font-size) !important;
  font-weight: var(--g-font-weight-light) !important;
  height: var(--g-default-input-height) !important;
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s !important;
  width: 100% !important;
}

.c-qr-code__tooltip {
  position: absolute;
  right: var(--g-spacing-3x-large);
}

.c-information-box__link {
  color: var(--g-color-brand-tertiary);
}

.c-div-over-content__div {
  background: white;
  z-index: 999999999999999999999;
  position: absolute;
  width: 100%;
  height: 100%;
}

.CookieConsent {
  padding: var(--g-spacing-4x-large) var(--g-spacing-4x-large);
  z-index: var(--z-cookie-consent);
}

/* CUSTOM STYLES CREATED IN THE CMS */

.ek-text-block {
  background-color: #ebe7ed;
  padding: var(--g-spacing-2x-large);
  border-radius: 10px;
  margin-bottom: var(--g-spacing-x-large);
}

.ek-infobox {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-radius: 0 10px 10px;
  display: flex;
  left: 0;
  margin: var(--spacing-12) 0;
  padding: var(--spacing-5) var(--spacing-8) var(--spacing-2) !important;
  position: relative;
  width: calc(100% - var(--spacing-2));
}

.ek-infobox .c-snippet__icon--left-top {
  align-items: center;
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: calc(var(--spacing-12) * -1);
  left: -1px;
  display: flex;
  height: var(--spacing-12);
  justify-content: center;
  padding: var(--spacing-2);
  width: var(--spacing-12);
}

.c-cms-container {
  display: flex;
  flex-direction: column;
}


.c-sidebar__overlay {
  /* to overlay the help beacon */
  z-index: 1050;
}

/* temporary fix for ui navigation card coverlink issue */
.c-settings-button {
  z-index: 2;
}

.c-accordion__item-parent-container {
  margin-top: var(--g-spacing-x-small);
}

.c-toggle-switch-wrapper {
  width: 100%;
}

/* JS Functional Classes */
.js-qr-code-hidden-downloader,
.js-qr-code-bulk-hidden-downloader,
.js-single-qr-code-hidden-downloader {
  position: absolute;
  top: var(--g-hidden-qr-code-generator-screen-offset);
}

.js-qr-code-hidden-downloader__code-wrapper {
  display: flex;
  flex-direction: column;
}

/* EK CMS Overrides */
.ek-theme-info {
  border-color: var(--burgundy-400) !important;
}

.ek-theme-info .c-snippet__icon--left-top {
  background-color: var(--burgundy-400) !important;
}

.ek-theme-example {
  border-color: var(--pear) !important;
}

.ek-theme-example .c-snippet__icon--left-top {
  background-color: var(--pear) !important;
}

.ek-theme-important {
  border-color: var(--monza) !important;
}

.ek-theme-important .c-snippet__icon--left-top {
  background-color: var(--monza) !important;
}

.ek-theme-tip {
  border-color: var(--pizazz) !important;
}

.ek-theme-tip .c-snippet__icon--left-top {
  background-color: var(--pizazz) !important;
}

@media (min-width: 48em) {
  .ek-infobox {
    left: var(--spacing-12);
    margin-top: 0;
    width: calc(100% - var(--spacing-14));
  }

  .ek-infobox .c-snippet__icon--left-top {
    top: -1px;
    left: calc(var(--spacing-12) * -1);
    transform: rotate(270deg);
  }

  .ek-infobox .c-snippet__icon--left-top img {
    transform: rotate(90deg);
  }

  .c-qr-code-type-radio-buttons > div:nth-child(2) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
  }

  .c-qr-code-type-radio-buttons > div:nth-child(1) {
    display: none;
  }
}

/* Shared Library Component Overrides */
.c-link-button {
  padding: var(--g-spacing-small) var(--g-spacing-x-large);
}

.c-checkbox__input:checked + .c-label .c-checkbox__icon-container {
  background-color: var(--purple-700);
}

.c-table__tbody-tr:hover {
  border-left-color: var(--purple-700);
}

.c-table__thead-th {
  vertical-align: middle;
}

.c-table-download-icon {
  margin-right: var(--g-spacing-2x-small);
}

.c-table-download-icon:hover:enabled,
.c-table-download-icon:active,
.c-table-download-icon:focus {
  background-color: transparent !important;
  border-color: var(--purple-700) !important;
  box-shadow: 0 0 0 var(--g-spacing-3x-small) var(--purple-700), inset 0 0 0 var(--g-spacing-3x-small) var(--g-color-white) !important;
  border-radius: var(--spacing-base);
}

.c-button--primary {
  background-color: var(--purple-700);
  color: var(--g-color-white);
  box-shadow: unset !important;
}

.c-button--primary-inverse:hover {
  background-color: var(--purple-700);
  color: var(--g-color-white);
  box-shadow: unset !important;
}

.c-button--secondary {
  background-color: var(--white);
  border: 1px solid transparent;
  box-shadow: unset !important;
  color: var(--g-color-black);
}

.c-button--secondary:hover {
  background-color: var(--g-pink) !important;
  border: 1px solid transparent;
  color: var(--g-color-black) !important;
}

.c-impersonated-user-bar__container {
  align-items: center;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}

.c-impersonated-user-bar__logout-button-container {
  align-items: center;
  display: flex;
  margin-left: 0.5rem;
}

.c-impersonated-user-bar__user-full-name-container {
  align-items: center;
  display: flex;
  flex: 1;
  font-weight: bold;
  flex-direction: row;
  justify-content: flex-start;
}

.c-impersonated-user-bar__stop-button-container {
  align-items: center;
  display: flex;
  margin-left: 0.5rem;
  justify-content: flex-end;
}

.c-sidebar__container {
  padding-bottom: var(--g-spacing-6x-large);
}

.c-sidebar__container--right {
  /* z-index to overlay the help beacon */
  z-index: 2051;
}



.c-left-sidebar__list {
  display: flex;
  flex-direction: column;
  gap: var(--g-spacing-x-small);
}

.c-left-sidebar__list-item {
  align-items: center;
  border-radius: var(--g-border-radius-small);
  display: flex;
  font-size: var(--g-font-size-small);
  gap: 0.563rem;
  margin: 0;
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
}

.c-left-sidebar__list-item--active {
  background-color: var(--g-color-grey-50);
}

.c-left-sidebar__list-item__icon {
  height: 0.813rem;
  width: 0.813rem;
}


.l-container {
  display: block;
}

.c-input-label {
  font-weight: 300;
  font-size: var(--g-font-size-x-small);
  display: block;
  margin-bottom: var(--g-spacing-x-small);
}

@media (min-width: 42em) {
  .c-page-preview-list > li {
    width: 50%;
  }
}

@media (min-width: 64em) {
  .c-page-preview-list > li {
    width: 31%;
  }
}

.c-sidebar-nav__page-wrapper {
  width: auto !important;
}

.c-dropdown-navigation {
  z-index: 1;
}

.c-docs-link {
  border: none;
  background-color: transparent;
  color: var(--g-color-brand-tertiary);
  cursor: pointer;
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
  font-weight: var(--g-font-weight-light);
  line-height: 1.18;
  margin: 0;
  padding: 0;
  text-decoration: none;
}


.c-sidebar-nav__page-wrapper.is-pushed-right .c-page-container {
  margin: 0 auto;
  transform: translateX(-7%);
  transition: transform var(--g-transition-time) ease-in-out;
}

.c-page-container {
  max-width: 125rem;
  margin: 0 auto;
}

.c-table-page-template .c-page-container > .l-container {
  display: block;
}

.c-empty-state--auto .c-empty-state__image {
  max-width: 30rem;
}

@media (max-width: 38em) {
  .c-sidebar__container--is-hidden--left {
    transform: translateX(-100%);
  }
}

.c-dialog-overlay {
  z-index: 2100;
}

.c-cookie-consent__inner {
  z-index: 2050;
}

@media (max-width: 28.75em) {
  .c-cookie-consent__buttons {
    flex-direction: row !important;
  }

  .c-cookie-consent__buttons .c-button {
    padding: var(--g-spacing-2x-small) var(--g-spacing-small);
  }

  .c-cookie-consent__inner {
    padding: var(--g-spacing);
  }
}

.c-become-a-partner .c-label {
  color: var(--g-color-white);
}


.c-payment-page-wrapper {
  display: grid;
  grid-template-columns: minmax(20rem, 1fr) 3fr;
  gap: var(--g-spacing-large);
  padding-top: var(--g-spacing-2x-large);
  padding-bottom: var(--g-spacing-6x-large);
}

.c-footer {
  z-index: 2;
}

.c-tablist__nav {
  padding: 0 var(--g-spacing-2x-large) 0 var(--g-spacing-small);
}


@media (max-width: 74em) {
  .c-payment-page-wrapper {
    grid-template-columns: 1fr;
  }

}
